import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
/* -------------------------------
 * 
 --------------------------------*/

import businessCardTarget from '../../assets/swiss.mind' 
import penModel from '../../assets/pen_assembled.glb'
import demonstratorModel from '../../assets/demonstrator_model_compined.glb'

import { MaterialMapper } from './MaterialMapper';


const ArViewer =()=> {
  /*-------------------------------------------
   * Handle Pen Model
   --------------------------------------------*/
  const penRef = useRef(null)
  const penConfig = useSelector(state => state.pen)
  /**
   * The Function will get all meshes of the gltf object
   * @returns Array 
   */

  
  const getPenMesh = (name)=>{
    if(penRef.current){
      return penRef.current.object3D.children[0].children.find((child)=> child.name === name)
    }
  }

  const getPenAnimations = ()=>{
    if(penRef.current){
      return penRef.current.object3D.children[0].animations
    }
  }

  const getPenScene = ()=>{
    if(penRef.current){
      return penRef.current.object3D.children[0]
    }
  }

  const changePenColor = (color, part) =>{
    if(penRef.current){
      const newMaterial = MaterialMapper(color)
      newMaterial.name = `${part}`.toLowerCase() + 'Color'
      getPenMesh(part).material = newMaterial
      newMaterial.dispose()
    }
  }

  /*-------------------------------------------
   * States for Pen and Demonstrator Model 
   --------------------------------------------*/

  const state = useSelector((state) => state.state)
  const [showDemonstrator, setShowDemonstrator] = useState(true)
  const [showPen, setShowPen] = useState(false)
  useEffect(()=>{
    if(state.location !== 'start'){
      setShowDemonstrator(false)
      setShowPen(true)
    }
    
    if(state.location === 'start'){
      setShowDemonstrator(true)
      setShowPen(false)
    }
  },[state])

  /*-------------------------------------------
   * Ar Sytem Object 
   --------------------------------------------*/

  const arSystem = {
    sceneEl: null,
    arSystem: null,
    sceneRef: useRef(null),

    async create() {
      this.sceneEl =  await this.sceneRef.current
      this.arSystem = this.sceneEl.systems['mindar-image-system']

      this.sceneEl.addEventListener('renderstart', () => {
        this.arSystem.start()
      })
    },

    stop() {
      if (this.arSystem) {
        this.arSystem.stop()
      }
    }
  }

  /*-------------------------------------------
   * Start and Stop the Ar System when the Component
   * Mounts and unmounts 
   --------------------------------------------*/
  useEffect(() => {
    arSystem.create()
    return () => {
      arSystem.stop()
    }
  }, []);

  /*-------------------------------------------
   * Handle Color Change
   --------------------------------------------*/
  const [animation, setAnimation] = useState('clip: none')

  const changeTubeColor = async()=>{
    if(animation !== "clip: *1; loop: once"){
      const animationDuration = 2750
      setAnimation("clip: *1; loop: once")
      setTimeout(()=>{
        changePenColor(penConfig.tube_color, 'Tube')
      }, animationDuration * 0.5)
      setTimeout(()=>{
        setAnimation("clip: none")
      },animationDuration)
    } 
  }
  const changeCapColor = async()=>{
    if(animation !== "clip: *2; loop: once"){
      const animationDuration = 2750
      setAnimation("clip: *2; loop: once")
      setTimeout(()=>{
        changePenColor(penConfig.cap_color, 'Cap')
      }, animationDuration * 0.5)
      setTimeout(()=>{
        setAnimation("clip: none")
      },animationDuration)
    } 
  }

  const changeRefilColor = async()=>{
    if(animation !== "clip: *3; loop: once"){
      const animationDuration = 2750
      setAnimation("clip: *3; loop: once")
      setTimeout(()=>{
        setAnimation("clip: none")
      },animationDuration)
    } 
  }
  
  useEffect(()=>{
    if(penRef.current){
      changeTubeColor()
    }
  },[penConfig.tube_color])
  
  useEffect(()=>{
    if(penRef.current){
      changeCapColor()
    }
  },[penConfig.cap_color])
  
  useEffect(()=>{
    if(penRef.current){
      changeRefilColor()
    }
  },[penConfig.refill_color])

  return (
      <a-scene ref={arSystem.sceneRef} mindar-image={`imageTargetSrc: ${businessCardTarget}; autoStart: false; uiLoading: no; uiError: no; uiScanning: no;`} color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
        <a-assets>
          {/* <img id="card" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.0.0/examples/image-tracking/assets/card-example/card.png" crossOrigin="anonymous" /> */}
          <a-asset-item id="penModel" src={penModel}></a-asset-item>
          <a-asset-item id="demonstratorModel" src={demonstratorModel}></a-asset-item>
        </a-assets>
        <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>
        <a-entity mindar-image-target="targetIndex: 0" >
          {showPen &&  <a-gltf-model ref={penRef} rotation="45 -90 225" position="0 0 0.25" scale="10 10 10" src="#penModel"  animation-mixer={animation}></a-gltf-model>}
          {showDemonstrator &&  <a-gltf-model rotation="90 0 0" position="0 0 0" scale="0.05 0.05 0.05" src="#demonstratorModel"></a-gltf-model>}
        </a-entity>
      </a-scene>
  )
}

export default ArViewer
