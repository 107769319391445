import React from 'react';

/** ------------------------------------------------
 * Ar imports
 -------------------------------------------------*/
import 'mind-ar/dist/mindar-image.prod.js';
import 'mind-ar/dist/mindar-image-aframe.prod.js';
import './AR.css';

/** ------------------------------------------------
 * Component Imports
 -------------------------------------------------*/

import ArViewer from './ArViewer';

function AR() {

  return (
    <div className="ar__container">
      <ArViewer />
      <video></video>
    </div>
  );
}

export default AR;