// Library imports
import { useDispatch, useSelector } from "react-redux";

// Components imports
import GuiLayout from "../GuiLayout";
import InputField from "../UiElements/InputField";
import Button from "../UiElements/Button";

// Action import
import { penActions } from "../../../store/pen-slice";

// Variables import
import { NamesWithLanguage } from "../Variables/Names";

// CSS Import
import "./PersonalInformationPage.css";


// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const PersonalInformationPage = () => {
  const dispatch = useDispatch();

  const currentName = useSelector((state) => state.pen.name);
  const addNameToStore = (value) => {
    dispatch(penActions.changeName(value));
  };

  const currentMail = useSelector((state) => state.pen.mail);
  const addMailToStore = (value) => {
    dispatch(penActions.changeMail(value));
  };

  const currentCompany = useSelector((state) => state.pen.company);
  const addCompanyToStore = (value) => {
    dispatch(penActions.changeCompany(value));
  };

  return (
    <GuiLayout className="input-fields">
      <h2 className="title">{NamesWithLanguage("personal")}</h2>
      <div className="input-container">
        <InputField
          label="Name"
          placeholder="Vorname, Nachname"
          currentValue={currentName}
          inputValueFn={addNameToStore}
        />
        <InputField
          label="Mail"
          placeholder="max-muster@zhaw.ch"
          currentValue={currentMail}
          inputValueFn={addMailToStore}
        />
        <InputField
          label="Firma"
          placeholder="ZHAW"
          currentValue={currentCompany}
          inputValueFn={addCompanyToStore}
        />
      </div>

      <Button style="back" location="name_input" />
      <Button style="next" location="overview" />
    </GuiLayout>
  );
};

export default PersonalInformationPage;
