export const MaterialMapper = (color) => {
    switch (color) {
        case "black":
            const colorBlack = new window.THREE.Color(0, 0, 0)
            return new window.THREE.MeshStandardMaterial({ color: colorBlack, side: window.THREE.DoubleSide, roughness: 0.2, metalness:0 });
        case "tint_blue":
            const colorTintBlue = new window.THREE.Color(0, 0, 0)
            return new window.THREE.MeshStandardMaterial({ color: colorTintBlue, side: window.THREE.DoubleSide, roughness: 0.2, metalness:0 });
        case "red":
            const red = new window.THREE.Color(0.6382719874382019, 0, 0.017285052686929703)
            return new window.THREE.MeshStandardMaterial({ color: red, side: window.THREE.DoubleSide, roughness: 0.2, metalness:0 });
        case "red_transparent":
            const redTransparent = new window.THREE.Color(0.6382719874382019, 0, 0.017285052686929703)
            return new window.THREE.MeshStandardMaterial({ color: redTransparent, side: window.THREE.DoubleSide, opacity: 0.75, roughness: 0.2, metalness:0, transparent: true });
        case "blue":
            const blue = new window.THREE.Color(0.02800000086426735, 0.02800000086426735, 0.47999998927116394)
            return new window.THREE.MeshStandardMaterial({ color: blue, side: window.THREE.DoubleSide, roughness: 0.2, metalness:0 });
        case "blue_transparent":
            const blueTransparent = new window.THREE.Color(0.02800000086426735, 0.02800000086426735, 0.47999998927116394)
            return new window.THREE.MeshStandardMaterial({ color: blueTransparent, side: window.THREE.DoubleSide, opacity: 0.75, roughness: 0.2, metalness:0, transparent: true });
        case "green":
            const green = new window.THREE.Color(0.012000000104308128, 0.15199999511241913, 0.04800000041723251)
            return new window.THREE.MeshStandardMaterial({ color: green, side: window.THREE.DoubleSide, roughness: 0.2, metalness:0 });
        case "green_transparent":
            const greenTransparent = new window.THREE.Color(0.012000000104308128, 0.15199999511241913, 0.04800000041723251)
            return new window.THREE.MeshStandardMaterial({ color: greenTransparent, side: window.THREE.DoubleSide, opacity: 0.75, roughness: 0.2, metalness:0, transparent: true });
        case "silver":
            const silver = new window.THREE.Color(0.5916019678115845, 0.5916019678115845, 0.5916019678115845)
            return new window.THREE.MeshStandardMaterial({ color: silver, side: window.THREE.DoubleSide, roughness: 0.4, metalness: 0.85 });
        case "gold":
            const gold = new window.THREE.Color(0.7356570959091187, 0.5562095642089844, 0.24259941279888153)
            return new window.THREE.MeshStandardMaterial({ color: gold, side: window.THREE.DoubleSide, roughness: 0.5, metalness: 0.85 });
    }
}