export const arrowIcon = (
  <svg
    className="overview-arrow icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 28.52"
  >
    <path d="M25,28.52a3.5,3.5,0,0,1-2.48-1L1,6a3.5,3.5,0,0,1,5-5l19,19L44,1a3.51,3.51,0,0,1,5,5L27.47,27.49A3.5,3.5,0,0,1,25,28.52Z"></path>
  </svg>
);

export const successIcon = (
  <svg
    className="success-icon icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
  >
    <path d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896zm-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"></path>
  </svg>
);

export const errorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="error-icon icon"
    viewBox="0 0 1024 1024"
  >
    <path d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512L353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336L616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512L670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"></path>
  </svg>
);
