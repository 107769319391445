// Library imports
import { useDispatch, useSelector } from "react-redux";

// Components imports
import GuiLayout from "../GuiLayout";
import InputField from "../UiElements/InputField";
import Button from "../UiElements/Button";

// Action import
import { penActions } from "../../../store/pen-slice";

// Variables import
import { NamesWithLanguage } from "../Variables/Names";

const NameInputPage = () => {
  const dispatch = useDispatch();

  const currentText = useSelector((state) => state.pen.text);
  const inputValueFn = (value) => {
    dispatch(penActions.changeText(value));
  };

  return (
    <GuiLayout className="input-fields">
      <h2 className="title">{NamesWithLanguage("text")}</h2>
      <InputField
        label="Text auf dem Stift"
        placeholder="Schreiben Sie etwas ..."
        inputValueFn={inputValueFn}
        currentValue={currentText}
        maxLength={15}
      />
      <Button style="back" location="tube_color" />
      <Button style="next" location="personal_info" />
    </GuiLayout>
  );
};

export default NameInputPage;
