export const RefillColors = ["black", "tint_blue"];

export const TubeColors = [
  "red",
  "red_transparent",
  "blue",
  "blue_transparent",
  "green",
  "green_transparent",
  "silver",
  "gold",
];

export const CapColors = [
  "red",
  "red_transparent",
  "blue",
  "blue_transparent",
  "green",
  "green_transparent",
  "silver",
  "gold",
];

export const ColorMapper = (color) => {
  switch (color) {
    case "black":
      return "#000000";
    case "tint_blue":
      return "#45b6fe";
    case "red":
      return "#d63031";
    case "red_transparent":
      return "#fab1a0";
    case "blue":
      return "#0984e3";
    case "blue_transparent":
      return "#81ecec";
    case "green":
      return "#00b894";
    case "green_transparent":
      return "#55efc4";
    case "silver":
      return "#bdc3c7";
    case "gold":
      return "#fdcb6e";
  }
};
