// Library imports
import { useDispatch } from "react-redux";

// Action import
import { stateActions } from "../../../store/state-slice";

// CSS import
import "./Button.css";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //

const Button = ({ style, location, text, buttonFn }) => {
  const dispatch = useDispatch();

  const buttonText = text || (style === "next" ? "Weiter" : "Zurück");

  return (
    <button
      className={`button ${style === "next" ? "next" : "back"}`}
      onClick={
        buttonFn
          ? () => buttonFn()
          : () => dispatch(stateActions.changeState(location))
      }
    >
      {buttonText}
    </button>
  );
};

export default Button;
