// Library imports
import { useDispatch, useSelector } from "react-redux";

// Components imports
import StartPages from "./Pages/StartPages";
import RefillColorPage from "./Pages/RefillColorPage";
import CapColorPage from "./Pages/CapColorPage";
import TubeColorPage from "./Pages/TubeColorPage";
import NameInputPage from "./Pages/NameInputPage";
import PersonalInformationPage from "./Pages/PersonalInformationPage";
import OverviewPage from "./Pages/OverviewPage";
import ServerFeedback from "./Pages/ServerFeedback";

// Actions import
import { penActions } from "../../store/pen-slice";

// CSS imports
import "./GuiContainer.css";

const GuiContainer = () => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.state.location);

  location === "start" && dispatch(penActions.resetPen());

  return (
    <div className="gui-container">
      {location === "start" ? (
        <StartPages />
      ) : location === "refill_color" ? (
        <RefillColorPage />
      ) : location === "cap_color" ? (
        <CapColorPage />
      ) : location === "tube_color" ? (
        <TubeColorPage />
      ) : location === "name_input" ? (
        <NameInputPage />
      ) : location === "personal_info" ? (
        <PersonalInformationPage />
      ) : location === "overview" ? (
        <OverviewPage />
      ) : location === "server_success" ? (
        <ServerFeedback />
      ) : location === "server_error" ? (
        <ServerFeedback />
      ) : (
        <StartPages />
      )}
    </div>
  );
};

export default GuiContainer;
