// Library imports
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import 'aframe';
import 'aframe-extras'

// Components imports
import App from "./App";
import store from "./store/store";

// CSS import
import "./index.css";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
