// Library import
import { motion } from "framer-motion";

// CSS Import
import "./GuiLayout.css";

const GuiLayout = ({ className, children }) => {
  const classes = className + " gui-child";

  return (
    <div className="gui-layout">
      <motion.div
        className={classes}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default GuiLayout;
