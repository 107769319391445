// Library imports
import { useDispatch, useSelector } from "react-redux";

// Components imports
import GuiLayout from "../GuiLayout";
import ColorElement from "../UiElements/ColorElement";
import Button from "../UiElements/Button";

// Action import
import { penActions } from "../../../store/pen-slice";

// Variables import
import { TubeColors } from "../Variables/Colors";
import { NamesWithLanguage } from "../Variables/Names";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const TubeColorPage = () => {
  const dispatch = useDispatch();

  const currentColor = useSelector((state) => state.pen.tube_color);

  const pickedCapColor = (pickedColor) => {
    dispatch(penActions.changeTubeColor(pickedColor));
  };

  return (
    <GuiLayout className="color-pick">
      <h2 className="title">{NamesWithLanguage("tube_color")}</h2>
      <div className="color-container">
        {TubeColors.map((color, i) => (
          <ColorElement
            color={color}
            colorPickedFn={pickedCapColor}
            number={i}
            current={currentColor === color}
          />
        ))}
      </div>
      <Button style="back" location="cap_color" />
      <Button style="next" location="name_input" />
    </GuiLayout>
  );
};

export default TubeColorPage;
