// Components imports
import GuiLayout from "../GuiLayout";
import Button from "../UiElements/Button";

// CSS import
import "./StartPage.css"

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const StartPages = () => {
  return (
    <GuiLayout className="start-page">
      <h2 className="title">Willkommen</h2>
      <Button style="next" location="refill_color" text="Start" />
    </GuiLayout>
  );
};

export default StartPages;
