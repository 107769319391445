// Library imports
import { QueryClient, QueryClientProvider } from "react-query";
import { AnimatePresence } from "framer-motion";

// Components imports
import AR from "./components/AR/AR";
import GuiContainer from "./components/GUI/GuiContainer";

// New Query Client
const queryClient = new QueryClient();

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AR />
      <AnimatePresence>
        <GuiContainer />
      </AnimatePresence>
    </QueryClientProvider>
  );
};

export default App;
