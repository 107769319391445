import { createSlice } from "@reduxjs/toolkit";

const penSlice = createSlice({
  name: "pen",
  initialState: {
    refill_color: "black",
    cap_color: "blue",
    tube_color: "red",
    text: "",
    name: "",
    company: "",
    mail: "",
  },
  reducers: {
    changeCapColor(state, action) {
      state.cap_color = action.payload;
    },
    changeTubeColor(state, action) {
      state.tube_color = action.payload;
    },
    changeRefillColor(state, action) {
      state.refill_color = action.payload;
    },
    changeText(state, action) {
      state.text = action.payload;
    },
    changeName(state, action) {
      state.name = action.payload;
    },
    changeCompany(state, action) {
      state.company = action.payload;
    },
    changeMail(state, action) {
      state.mail = action.payload;
    },
    resetPen(state) {
      state.refill_color = "black";
      state.cap_color = "blue";
      state.tube_color = "red";
      state.text = "";
      state.name = "";
      state.company = "";
      state.mail = "";
    },
  },
});

export const penActions = penSlice.actions;
export default penSlice;
