const lang = "DE";

export const NamesWithLanguage = (input) => {
  switch (lang) {
    case "EN":
      if (input === "cap_color") {
        return "Cap color";
      } else if (input === "tube_color") {
        return "Tube Color";
      } else if (input === "refill_color") {
        return "Refill Color";
      } else if (input === "text") {
        return "Text";
      } else if (input === "welcome") {
        return "Welcome";
      } else if (input === "overview") {
        return "Overview";
      } else if (input === "personal") {
        return "Personal information";
      } else if (input === "price") {
        return "Price";
      }
      // Colors
      else if (input === "black") {
        return "Black";
      } else if (input === "tint_blue") {
        return "Blue";
      } else if (input === "blue") {
        return "Blue";
      } else if (input === "blue_transparent") {
        return "Blue (Transparent)";
      } else if (input === "red") {
        return "Red";
      } else if (input === "red_transparent") {
        return "Red (Transparent)";
      } else if (input === "green") {
        return "Green";
      } else if (input === "green_transparent") {
        return "Green (Transparent)";
      } else if (input === "silver") {
        return "Silver";
      } else if (input === "gold") {
        return "Gold";
      } else {
        return "-";
      }
    case "DE":
      if (input === "cap_color") {
        return "Farbe oben";
      } else if (input === "tube_color") {
        return "Farbe unten";
      } else if (input === "refill_color") {
        return "Minenfarbe";
      } else if (input === "text") {
        return "Text";
      } else if (input === "welcome") {
        return "Willkommen";
      } else if (input === "overview") {
        return "Übersicht";
      } else if (input === "personal") {
        return "Persönliche Information";
      } else if (input === "price") {
        return "Preis";
      }
      // Colors
      else if (input === "black") {
        return "Schwarz";
      } else if (input === "tint_blue") {
        return "Blau";
      } else if (input === "blue") {
        return "Blau";
      } else if (input === "blue_transparent") {
        return "Blau (Transparent)";
      } else if (input === "red") {
        return "Rot";
      } else if (input === "red_transparent") {
        return "Rot (Transparent)";
      } else if (input === "green") {
        return "Grün";
      } else if (input === "green_transparent") {
        return "Grün (Transparent)";
      } else if (input === "silver") {
        return "Silber";
      } else if (input === "gold") {
        return "Gold";
      }
  }
};

export const Price = () => (lang === "DE") ? "Gratis" : "Free"