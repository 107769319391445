// Variables import
import { ColorMapper } from "../Variables/Colors";

// CSS import
import "./ColorElement.css";

const ColorElement = ({ color, colorPickedFn, number, current }) => {
  const colorCode = ColorMapper(color);

  return (
    <button
      key={number}
      onClick={() => colorPickedFn(color)}
      style={{ backgroundColor: colorCode }}
      className="color-button"
      data-current={current}
    ></button>
  );
};

export default ColorElement;
