import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "state",
  initialState: {
    location: "start",
  },
  reducers: {
    changeState(state, actions) {
      state.location = actions.payload;
    },
  },
});

export const stateActions = stateSlice.actions;
export default stateSlice;
