// Library imports
import { useDispatch, useSelector } from "react-redux";

// Components imports
import GuiLayout from "../GuiLayout";
import ColorElement from "../UiElements/ColorElement";
import Button from "../UiElements/Button";

// Action import
import { penActions } from "../../../store/pen-slice";

// Variables import
import { CapColors } from "../Variables/Colors";
import { NamesWithLanguage } from "../Variables/Names";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //

const CapColorPage = () => {
  const dispatch = useDispatch();

  const currentColor = useSelector((state) => state.pen.cap_color);

  const pickedCapColor = (pickedColor) => {
    dispatch(penActions.changeCapColor(pickedColor));
  };

  return (
    <GuiLayout className="color-pick">
      <h2 className="title">{NamesWithLanguage("cap_color")}</h2>
      <div className="color-container">
        {CapColors.map((color, i) => (
          <ColorElement
            color={color}
            colorPickedFn={pickedCapColor}
            number={i}
            current={currentColor === color}
          />
        ))}
      </div>
      <Button style="back" location="refill_color" />
      <Button style="next" location="tube_color" />
    </GuiLayout>
  );
};

export default CapColorPage;
