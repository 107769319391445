// Library imports
import { useSelector } from "react-redux";

// Components imports
import GuiLayout from "../GuiLayout";
import Button from "../UiElements/Button";

// Icons import
import { successIcon, errorIcon } from "../Variables/Icons";

// CSS import
import "./ServerFeedback.css";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const ServerFeedback = () => {
  const serverResponse = useSelector((state) => state.state.location);

  console.log(serverResponse);
  return serverResponse === "server_success" ? (
    <GuiLayout className="server-feedback">
      <h2 className="title">Erfolgreich</h2>
      {successIcon}
      <Button text="schliessen" style="back" location="start" />
    </GuiLayout>
  ) : (
    <GuiLayout className="server-feedback">
      <h2 className="title">Fehlgeschlagen</h2>
      {errorIcon}
      <Button text="Nochmals" style="back" />
    </GuiLayout>
  );
};

export default ServerFeedback;
