// CSS import
import "./InputField.css";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const InputField = ({
  label,
  placeholder,
  currentValue,
  inputValueFn,
  maxLength,
}) => {
  return (
    <label className="input-field-container">
      {label}
      <input
        type="text"
        className="input-field"
        placeholder={placeholder}
        value={currentValue}
        onChange={(field) => inputValueFn(field.target.value)}
        maxlength={maxLength}
      />
    </label>
  );
};

export default InputField;
