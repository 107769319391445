// CSS import
import "./OverviewPage.css";
// Library imports
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { motion, transform } from "framer-motion";

// Components imports
import GuiLayout from "../GuiLayout";
import Button from "../UiElements/Button";

// Variables import
import { NamesWithLanguage, Price } from "../Variables/Names";

// Icon import
import { arrowIcon } from "../Variables/Icons";

// Action import
import { stateActions } from "../../../store/state-slice";

// Animations Variantes 
const variants = {
  open: {rotate: 0},
  closed: {rotate: 180}
}

// Post Function
const sendPenToDatabase = async (config) => {
  const { data } = await axios.post(
    "https://ims-zhaw.ch/save-pen-in-database.php",
    config
  );
  return data;
};

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const OverviewPage = () => {
  const currentPen = useSelector((state) => state.pen);
  const [isOpen, setIsOpen] = useState(true);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(sendPenToDatabase, {
    onSuccess: (data) => {
      dispatch(stateActions.changeState("server_success"));
    },
    onError: () => {
      dispatch(stateActions.changeState("server_error"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const orderBtnPressed = () => mutate(currentPen);

  return (
    <GuiLayout className="overview-page">
      <h2 className="title">{NamesWithLanguage("overview")}</h2>
      <motion.div
        className="show-hide-container"
        onClick={() => setIsOpen(!isOpen)}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        {arrowIcon}
      </motion.div>
      {isOpen === true && (
        <div className="overview-table-container">
          <div className="config-part">
            <p className="config-title">{NamesWithLanguage("refill_color")}</p>
            <p className="config-value">
              {NamesWithLanguage(currentPen.refill_color)}
            </p>
          </div>
          <div className="config-part">
            <p className="config-title">{NamesWithLanguage("cap_color")}</p>
            <p className="config-value">
              {NamesWithLanguage(currentPen.cap_color)}
            </p>
          </div>
          <div className="config-part">
            <p className="config-title">{NamesWithLanguage("tube_color")}</p>
            <p className="config-value">
              {NamesWithLanguage(currentPen.tube_color)}
            </p>
          </div>

          <div className="config-part">
            <p className="config-title">{NamesWithLanguage("text")}</p>
            <p className="config-value">{currentPen.text}</p>
          </div>

          <div className="config-part price">
            <p className="config-title">{NamesWithLanguage("price")}</p>
            <p className="config-value">{Price()}</p>
          </div>
        </div>
      )}
      <Button style="back" location="personal_info" />
      <Button style="next" text="Bestellen" buttonFn={orderBtnPressed} />
    </GuiLayout>
  );
};

export default OverviewPage;
