// Library imports
import { configureStore } from "@reduxjs/toolkit";

// Slices imports
import penSlice from "./pen-slice";
import stateSlice from "./state-slice";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //

const store = configureStore({
  reducer: { pen: penSlice.reducer, state: stateSlice.reducer },
});

export default store;
