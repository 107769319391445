// Library imports
import { useDispatch, useSelector } from "react-redux";

// Components imports
import GuiLayout from "../GuiLayout";
import ColorElement from "../UiElements/ColorElement";
import Button from "../UiElements/Button";

// Action import
import { penActions } from "../../../store/pen-slice";

// Variables import
import { RefillColors } from "../Variables/Colors";
import { NamesWithLanguage } from "../Variables/Names";

// -------------------------------------------------- //
// ------------------ Start Component --------------- //
// -------------------------------------------------- //
const RefillColorPage = () => {
  const dispatch = useDispatch();

  const currentColor = useSelector((state) => state.pen.refill_color);

  const pickedRefillColor = (pickedColor) => {
    dispatch(penActions.changeRefillColor(pickedColor));
  };

  return (
    <GuiLayout className="color-pick refill-color">
      <h2 className="title">{NamesWithLanguage("refill_color")}</h2>
      <div className="color-container">
        {RefillColors.map((color, i) => (
          <ColorElement
            color={color}
            colorPickedFn={pickedRefillColor}
            number={i}
            current={currentColor === color}
          />
        ))}
      </div>
      <Button style="back" location="start" text="Abbrechen" />
      <Button style="next" location="cap_color" />
    </GuiLayout>
  );
};

export default RefillColorPage;
